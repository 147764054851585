import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import api from "./../Api"
import { Typography, Row, Col, Layout, Spin } from "antd";
import NavBar from "../Components/NavBar";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Calculator from "../Components/Calculator";
import SEO from "../Components/SEO";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const Page = () => {
  const [page, setPage] = useState({});
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const match = useRouteMatch("/page/:slug");
  const history = useHistory();

  useEffect(() => {
    const fetchPages = async () => {
      await api.pages()
        .get()
        .then((response) =>
          response.data.filter((x) => x.slug === match.params.slug).length === 0
            ? history.push("/404")
            : setPage(
                response.data.find((x) => x.slug === match.params.slug)
              )
        );
      setLoading(false);
    };
    window.scrollTo(0, 0);
    fetchPages();
  }, [history, match.params.slug]);

  return (
    <Spin spinning={loading}>
      <Layout>
        <NavBar
          from={page.id}
          cartCount={cartCount}
          setCartCount={setCartCount}
        />
        <SEO
          pageProps={{
            title: page.title,
            thumbnail: page.imageUrl ? page.imageUrl : "",
            url: window.location,
          }}
        />
        <Content style={{ margin: "24px 24px", maxWidth: "100%" }}>
          <Row type="flex" justify="center" gutter={32} align="top">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              {page.imageUrl ? (
                <img
                  src={page.imageUrl}
                  alt={page.title}
                  className="pageHeader"
                />
              ) : (
                ""
              )}
              <Typography>
                <Title>
                  {i18n.language === "en"
                    ? page.title
                    : page.translate !== null && page.translate
                    ? page.translate.title
                    : page.title}
                </Title>
                <Row type="flex" justify="center" gutter={32} align="top">
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    {match.params.slug === "credit" ? <Calculator /> : ""}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    lg={{ span: match.params.slug === "credit" ? 12 : 24 }}
                  >
                    <Paragraph>
                      {i18n.language === "en"
                        ? ReactHtmlParser(page.description)
                        : page.translatedDescription !== null &&
                          page.translatedDescription
                        ? ReactHtmlParser(page.translatedDescription)
                        : ReactHtmlParser(page.description)}
                    </Paragraph>
                  </Col>
                </Row>
              </Typography>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Spin>
  );
};

export default Page;
