import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Layout, Button, Col, Result } from "antd";
import NavBar from "../Components/NavBar";
import { useTranslation } from "react-i18next";
import SEO from "../Components/SEO";

const { Content } = Layout;

const SuccessPage = () => {
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const { t } = useTranslation();
  const history = useHistory();

  const handleAlreadyOrdered = () => {
    //setInputs({});
    history.push("/");
    window.location.reload();
  };

  return (
    <div id="success-page">
      <NavBar from={-2} cartCount={cartCount} setCartCount={setCartCount} />
      <SEO
        pageProps={{
          title: "IT Plaza LLC - Online supermarket of computers and accessories",
          thumbnail: "",
          url: window.location,
        }}
      />
      <Content style={{ margin: "24px 24px", maxWidth: "100%" }}>
      <Row justify="space-around" align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Result
          status="success"
          title={t("Order.ThankYou")}
          subTitle={t("Order.ThankYou2")}
          extra={[
            <Button onClick={handleAlreadyOrdered} type="primary" key="console">
              {t("Order.Back")}
            </Button>,
          ]}
        />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default SuccessPage;
