import axios from "axios";
import EndpointFactory from "axios-endpoints";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

const Endpoint = EndpointFactory(axiosInstance);

export default {
  images: (id = "") => new Endpoint(`/images/${id}`),
  productImages: (id = "") => new Endpoint(`/product-images/${id}`),
  productsImages: () => new Endpoint(`/products-images/`),
  banners: (id = "") => new Endpoint(`/banners/${id}`),
  uploads: (uid = "") => new Endpoint(`/uploads/${uid}`),
  categories: (id = "") => new Endpoint(`/categories/${id}`),
  manufacturers: (id = "") => new Endpoint(`/manufacturers/${id}`),
  products: (id = "") => new Endpoint(`/products/${id}`),
  pages: (id = "") => new Endpoint(`/pages/${id}`),
  posts: (id = "") => new Endpoint(`/posts/${id}`),
  specs: (id = "") => new Endpoint(`/specs/${id}`),
  informations: (id = "") => new Endpoint(`/informations/${id}`),
  orders: (id = "") => new Endpoint(`/orders/${id}`),
  prices: () => new Endpoint(`/prices`),
  geoZones: (id = "") => new Endpoint(`geozones/${id}`),
  featuredProducts: (id = "") => new Endpoint(`featured-products/${id}`),
  resultProducts: (id = "") => new Endpoint(`category-related-products/${id}`),
  categoryManufacturer: (id = "") => new Endpoint(`category-manufacturers/${id}`),
  getCountOfProducts: (id = "") => new Endpoint(`count-of-products/${id}`),
  acbaPaymentProvider: (id = "") => new Endpoint(`acba-payment-provider/${id}`),
  acbaPaymentStatus: (id = "") => new Endpoint(`acba-payment-result/${id}`),
  iDramPaymentProvider: (id = "") => new Endpoint(`idram-payment-provider/${id}`),
  iDramGateway: () => new Endpoint('https://banking.idram.am/Payment/GetPayment')
};
