import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import { Menu, Layout, Col } from "antd";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;
const { SubMenu } = Menu;

const API_URL = process.env.REACT_APP_API_URL;

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(
        data.filter((x) => x.parentId === 0).sort((a, b) => a.code - b.code)
      );
      setSubCategories(
        data.filter((x) => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
    };
    getCategories();
  }, [categories.length]);
  return {
    categories,
    subCategories,
  };
};

const SiderMenu = ({ setSCategory, collapse, setCollapse }) => {
  const { categories, subCategories } = useCategories();
  const { t, i18n } = useTranslation();
  const [banners, setBanners] = useState();
  const [broken, setBroken] = useState(true);

  useEffect(() => {
    const getBanners = async () => {
      const imagesUrls = [];
      await api
        .images()
        .get()
        .then((response) =>
          response.data.forEach((img) => {
            if (img.bannerName === "leftBanner") {
              imagesUrls.push({
                bannerName: img.bannerName,
                url: `${API_URL}/${img.url}`,
                additionalLink: img.additionalLink,
              });
            }
          })
        );
      setBanners(imagesUrls);
    };
    getBanners();
  }, []);

  const handleClick = (e) => {
    setSCategory(e.key);
  };
  return (
    <div>
      <Sider
        width="240"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          setBroken(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapse(broken ? collapsed : true);
        }}
      >
        <div className="categoriesName">{t("Sider.Headline")}</div>
        <Menu
          theme="dark"
          mode={broken ? "inline" : "vertical"}
          defaultSelectedKeys={["-1"]}
          onClick={handleClick}
          style={{ paddingTop: 16 }}
        >
          {categories.map((cat) =>
            subCategories.some((x) => x.parentId === cat.id) ? (
              <SubMenu
                key={cat.id}
                title={
                  <Link
                    to={{
                      pathname: "/result",
                      search: `?category=${cat.id}`,
                    }}
                  >
                    {i18n.language === "en"
                      ? cat.name
                      : cat.translate !== null && cat.translate.name
                      ? cat.translate.name
                      : cat.name}
                  </Link>
                }
              >
                {subCategories.map((sub) =>
                  cat.id === sub.parentId &&
                  subCategories.filter((x) => x.parentId === sub.id).length ===
                    0 ? (
                    <Menu.Item key={sub.id}>
                      <Link
                        to={{
                          pathname: "/result",
                          search: `?category=${sub.id}`,
                        }}
                      >
                        {i18n.language === "en"
                          ? sub.name
                          : sub.translate !== null && sub.translate.name
                          ? sub.translate.name
                          : sub.name}
                      </Link>
                    </Menu.Item>
                  ) : cat.id === sub.parentId &&
                    subCategories.some((x) => x.parentId === sub.id) ? (
                    <SubMenu
                      key={sub.id}
                      title={
                        <Link
                          to={{
                            pathname: "/result",
                            search: `?category=${sub.id}`,
                          }}
                        >
                          {i18n.language === "en"
                            ? sub.name
                            : sub.translate !== null && sub.translate.name
                            ? sub.translate.name
                            : sub.name}
                        </Link>
                      }
                    >
                      {subCategories.map((subChild) =>
                        cat.id === sub.parentId &&
                        subChild.parentId === sub.id ? (
                          <Menu.Item key={subChild.id}>
                            <Link
                              to={{
                                pathname: "/result",
                                search: `?category=${subChild.id}`,
                              }}
                            >
                              {i18n.language === "en"
                                ? subChild.name
                                : subChild.translate !== null &&
                                  subChild.translate.name
                                ? subChild.translate.name
                                : subChild.name}
                            </Link>
                          </Menu.Item>
                        ) : (
                          ""
                        )
                      )}
                    </SubMenu>
                  ) : (
                    ""
                  )
                )}
              </SubMenu>
            ) : (
              <Menu.Item key={cat.id}>
                <Link
                  to={{
                    pathname: "/result",
                    search: `?category=${cat.id}`,
                  }}
                >
                  {i18n.language === "en"
                    ? cat.name
                    : cat.translate !== null && cat.translate.name
                    ? cat.translate.name
                    : cat.name}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
      <Col xs={0} lg={24}>
        {banners &&
        banners.length > 0 &&
        banners.some((x) => x.bannerName === "leftBanner") ? (
          <a
            href={
              banners.find((x) => x.bannerName === "leftBanner").additionalLink
            }
          >
            <img
              src={banners.find((x) => x.bannerName === "leftBanner").url}
              alt="leftBanner"
              className="leftTowerBanner"
            />
          </a>
        ) : (
          ""
        )}
      </Col>
    </div>
  );
};

export default SiderMenu;
