import React, { Suspense, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import api from "./Api";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Product from "./Pages/Product";
import Page from "./Pages/Page";
import Result from "./Components/Result";
import "./i18next";
import FooterBar from "./Components/FooterBar";
import NotFoundPage from "./Pages/NotFoundPage";
import SuccessPage from "./Pages/SuccessPage";
import FailPage from "./Pages/FailPage";
import OrderDetailsPage from "./Pages/OrderDetailsPage";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { Spin } from "antd";
import HTML from "html-parse-stringify";

const IndexPage = () => {
  const [information, setInformation] = useState({});

  useEffect(() => {
    const getHelmet = async () => {
      await api
        .informations()
        .get()
        .then((response) => {
          setInformation(response.data[0]);

          let arr = HTML.parse(response.data[0].helmet);

          arr.forEach((domNode) => {
            if (domNode.name === "script") {
              var script = document.createElement("script");
              if (domNode.attrs.src) {
                script.src = domNode.attrs.src;
              }
              if(domNode.children && domNode.children.length > 0)
              {
                script.innerHTML = domNode.children[0].content;
              }
              script.async = domNode.attrs.async === "";
              script.onload = function () {};
              document.head.appendChild(script);
            }
          });
        });
    };
    getHelmet();
  }, []);

  return (
    <Spin spinning={!(information && information.helmet !== undefined)}>
      <Suspense fallback={<div>""</div>}>
         <Helmet>{ReactHtmlParser(information.helmet, {decodeEntities: true, transform(node) {
             if (node.type === 'tag' && node.name === 'noscript') {
                var noscript = document.createElement("noscript");
                if(node.children && node.children.length > 0)
                {
                  noscript.innerHTML = node.children[0].data;
                }
                document.head.appendChild(noscript);
                return null
            }
         }})}</Helmet>
        <Router>
          <div id="route">
            <Route
              render={() => (
                <Switch>
                  <Route exact path="/" component={App} />
                  <Route path="/product" component={Product} />
                  <Route path="/page" component={Page} />
                  <Route path="/success-payment" component={SuccessPage} />
                  <Route path="/fail-payment" component={FailPage} />
                  <Route path="/order-details" component={OrderDetailsPage} />
                  <Route path="/result" component={Result} />
                  <Route path="/404" component={NotFoundPage} />
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              )}
            />
          </div>
          <FooterBar />
        </Router>
      </Suspense>
    </Spin>
  );
};

ReactDOM.render(<IndexPage />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
