import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Row, Layout, Button, Col } from "antd";
import NavBar from "../Components/NavBar";
import { useTranslation } from "react-i18next";
import SEO from "../Components/SEO";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const NotFoundPage = () => {
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const { t } = useTranslation();

  return (
    <div id="not-found-page">
      <NavBar from={-1} cartCount={cartCount} setCartCount={setCartCount} />
      <SEO
        pageProps={{
          title: "404 Not Found - IT Plaza LLC - Online supermarket of computers and accessories",
          thumbnail: "",
          url: window.location,
        }}
      />
      <Content style={{ margin: "24px 24px", maxWidth: "100%" }}>
      <Row justify="space-around" align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Typography className="notFound">
            <Title>404</Title>
            <Paragraph>{t("Global.PageNotFound")}</Paragraph>
            <Link to="/">
            <Button type="primary" key="console">
              {t("Global.BackToHome")}
            </Button>
          </Link>
          </Typography>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default NotFoundPage;
