import React, { useState, useEffect } from "react";
import { Row, Divider, Col, Layout, Spin } from "antd";
import SiderMenu from "./Components/Sider";
import MainCarousel from "./Components/Carousel";
import "./App.css";
import Products from "./Components/Products";
import NavBar from "./Components/NavBar";
import api from "./Api";
import SEO from "./Components/SEO";

const { Content } = Layout;
const API_URL = process.env.REACT_APP_API_URL;

const App = () => {
  const [products, setProducts] = useState([]);
  const [sCategory, setSCategory] = useState(0);
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [banners, setBanners] = useState();
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      await api
        .featuredProducts()
        .get()
        .then((response) =>
          setProducts(response.data.sort((a, b) => a.ordering - b.ordering))
        );
    };
    const getBanners = async () => {
      const imagesUrls = [];
      await api
        .banners()
        .get()
        .then((response) => {
          response.data.forEach((img) => {
            if (
              img.bannerName === "rightBanner" ||
              img.bannerName === "rightBanner2" ||
              img.bannerName === "bottomBanner"
            ) {
              imagesUrls.push({
                bannerName: img.bannerName,
                url: `${API_URL}/${img.url}`,
                additionalLink: img.additionalLink,
              });
            }
          });
        });
      setBanners(imagesUrls);
    };
    getProducts();
    getBanners();
  }, []);

  return (
    <Spin spinning={loadingAnimation}>
      <Layout>
        <NavBar
          from={0}
          cartCount={cartCount}
          setCartCount={setCartCount}
          setLoadingAnimation={setLoadingAnimation}
        />        
        <SEO
        pageProps={{
          title: "IT Plaza LLC - Online supermarket of computers and accessories. Free delivery. Dealer sales.",
          thumbnail: "",
          url: window.location,
        }}
      />
        <Layout>
          <SiderMenu
            setSCategory={setSCategory}
            collapse={collapse}
            setCollapse={setCollapse}
          />
          <Content
            style={{
              margin: "24px 0 24px 16px",
              padding: 24,
              background: "#fff",
              minHeight: 280,
              borderRadius: 12,
              filter: !collapse ? "blur(30px)" : "",
            }}
          >
            <MainCarousel setLoadingAnimation={setLoadingAnimation} />
            <Divider />
            <Row gutter={16}>
              <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                <Products
                  products={products}
                  category={sCategory}
                  productsIds={products.map(x => x.id)}
                />
              </Col>
              <Col xs={{ span: 0 }} lg={{ span: 6 }}>
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "rightBanner") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "rightBanner")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "rightBanner").url
                      }
                      alt="rightBanner"
                      className="rightTowerBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "rightBanner2") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "rightBanner2")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "rightBanner2").url
                      }
                      alt="rightBanner2"
                      className="rightTowerBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Divider />
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "bottomBanner") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "bottomBanner")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "bottomBanner").url
                      }
                      alt="bottomBanner"
                      className="bottomBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default App;
