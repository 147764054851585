import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import api from "./../Api";
import { Layout, Menu, Row, Col, Button, Typography, Input, Icon } from "antd";
import logo from "../Images/logo.svg";
import Cart from "./Cart";
import { useTranslation } from "react-i18next";

const { Header } = Layout;
const { Text } = Typography;
const { Search } = Input;

const usePages = () => {
  const [pages, setPages] = useState(
    localStorage.getItem("pages")
      ? JSON.parse(localStorage.getItem("pages"))
      : []
  );
  const [topNavigation, setTopNavigation] = useState(
    localStorage.getItem("topNavigation")
      ? JSON.parse(localStorage.getItem("topNavigation"))
      : []
  );
  const [information, setInformation] = useState();

  useEffect(() => {
    const getPages = async () => {
      const { data } = await api.pages().get();
      localStorage.setItem(
        "pages",
        JSON.stringify(
          data
            .filter((x) => x.isInNavigation === true)
            .sort((a, b) => a.ordering - b.ordering)
        )
      );
      if (pages.length !== data.length) {
        setPages(
          data
            .filter((x) => x.isInNavigation === true)
            .sort((a, b) => a.ordering - b.ordering)
        );
      }
    };
    const getTopNavigation = async () => {
      const { data } = await api.pages().get();
      localStorage.setItem(
        "topNavigation",
        JSON.stringify(
          data
            .filter((x) => x.isInTopNavigation === true)
            .sort((a, b) => a.ordering - b.ordering)
        )
      );
      if (topNavigation.length !== data.length) {
        setTopNavigation(
          data
            .filter((x) => x.isInTopNavigation === true)
            .sort((a, b) => a.ordering - b.ordering)
        );
      }
    };
    const getInformation = async () => {
      await api
        .informations()
        .get()
        .then((response) => setInformation(response.data));
    };
    getPages();
    getTopNavigation();
    getInformation();
  }, [pages.length, topNavigation.length]);

  return {
    pages,
    topNavigation,
    information,
  };
};

const NavBar = ({ from, cartCount, setCartCount }) => {
  const { pages, topNavigation, information } = usePages();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchedText = params.get("search");
  const categoryId = params.get("categoryId");

  useEffect(() => {
    setSearchValue(searchedText);
  }, [searchedText]);

  const handleLangSwitch = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleOnSearch = (value) => {
    if (categoryId === null) {
      history.push({
        pathname: "/result",
        search: `?search=${value}`,
      });
    } else {
      history.push({
        pathname: "/result",
        search: `&search=${value}`,
      });
    }
    setSearchValue(value);
    window.location.reload();
  };

  return (
    <Header className="header" id="header">
      <Row type="flex" justify="space-between" align="bottom">
        <Col xs={{ span: 24 }} lg={{ span: 5 }}>
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 19 }}>
          <Row type="flex" justify="end" align="middle" className="headerBreak">
            {topNavigation.map((item) => (
              <Button
                key={item.id}
                type="dashed"
                size="small"
                className="topMenu"
                style={{ marginRight: 24 }}
              >
                <Link to={`/page/${item.slug}`}>
                  {i18n.language === "en"
                    ? item.title
                    : item.translate !== null
                    ? item.translate.title
                    : item.title}
                </Link>
              </Button>
            ))}
            <div className="langSwitch">
              <Text>
                <Icon
                  type="phone"
                  style={{
                    marginRight: 6,
                    fontSize: 18,
                    transform: "scaleX(-1)",
                    verticalAlign: "middle",
                  }}
                />
                {information &&
                  information.map((info, index) => (
                    <b key={index}>
                      {info.phone1}, {info.phone2}
                    </b>
                  ))}
              </Text>
              <div className="menu-search">
                <Button
                  type="dashed"
                  size="small"
                  onClick={() => handleLangSwitch("en")}
                  className={i18n.language === "en" ? "activeLanguage" : ""}
                >
                  <img
                    alt="gb"
                    width="20"
                    src="https://api.itplaza.am/api/uploads/gb.svg"
                  ></img>
                  ENG
                </Button>
                <Button
                  type="dashed"
                  size="small"
                  onClick={() => handleLangSwitch("am")}
                  className={i18n.language === "am" ? "activeLanguage" : ""}
                >
                  <img
                    alt="am"
                    width="20"
                    src="https://api.itplaza.am/api/uploads/am.svg"
                  ></img>
                  ՀԱՅ
                </Button>
              </div>
            </div>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <Search
              className="mobile-search"
              placeholder={searchValue ? searchValue : t("NavBar.SearchText")}
              onSearch={handleOnSearch}
              size="default"
            />
          </Row>
          <Row type="flex" justify="start" align="middle">
            <Col xs={{ span: 18 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={
                  from !== "ProductPage" ? [`${from}`] : [""]
                }
                selectedKeys={[`${from}`]}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Menu.Item key={-1} style={{ width: "100%" }}>
                  <Search
                    className="menu-search"
                    placeholder={
                      searchValue ? searchValue : t("NavBar.SearchText")
                    }
                    onSearch={handleOnSearch}
                    size="default"
                  />
                  <div className="langSwitch">
                    <div className="mobile-lang">
                      <Button
                        type="dashed"
                        size="small"
                        onClick={() => handleLangSwitch("en")}
                        className={
                          i18n.language === "en" ? "activeLanguage" : ""
                        }
                      >
                        <img
                          alt="gb"
                          width="20"
                          src="https://api.itplaza.am/api/uploads/gb.svg"
                        ></img>
                        ENG
                      </Button>
                      <Button
                        type="dashed"
                        size="small"
                        onClick={() => handleLangSwitch("am")}
                        className={
                          i18n.language === "am" ? "activeLanguage" : ""
                        }
                      >
                        <img
                          alt="am"
                          width="20"
                          src="https://api.itplaza.am/api/uploads/am.svg"
                        ></img>
                        ՀԱՅ
                      </Button>
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item key={0}>
                  <Link to="/">{t("Home.1")}</Link>
                </Menu.Item>
                {pages.map((page) => (
                  <Menu.Item key={page.id}>
                    <Link to={`/page/${page.slug}`}>
                      {i18n.language === "en"
                        ? page.title
                        : page.translate !== null
                        ? page.translate.title
                        : page.title}
                    </Link>
                  </Menu.Item>
                ))}
                <Menu.Item className="menu-search">
                  <Cart
                    from={from}
                    cartCount={cartCount}
                    setCartCount={setCartCount}
                  />
                </Menu.Item>
              </Menu>
            </Col>
            <Col
              xs={{ span: 6 }}
              md={{ span: 0 }}
              lg={{ span: 0 }}
              className="headerCartBreak"
            >
              <Cart
                from={from}
                cartCount={cartCount}
                setCartCount={setCartCount}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default NavBar;
