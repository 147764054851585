import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import api from "./../Api";
import {
  Row,
  Col,
  Layout,
  Card,
  Tag,
  Spin,
  Select,
  Form,
  Divider,
  Input,
  InputNumber,
  Checkbox,
} from "antd";
import NavBar from "../Components/NavBar";
import { useTranslation } from "react-i18next";
import SEO from "./SEO";
import { GetPrices, ImageUrl, ManufacturerImageUrl } from "./../Common";

const { Content } = Layout;
const { Meta } = Card;
const { Option, OptGroup } = Select;
const InputGroup = Input.Group;

const FetchProducts = () => {
  const [spinning, setSpinning] = useState(true);
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("category");
  const manufacturerId = params.get("manufacturer");
  const minimumPrice = parseFloat(params.get("minimum"));
  const maximumPrice = parseFloat(params.get("maximum"));
  const [searchedText, setSearchedText] = useState(
    params.get("search") !== null ? params.get("search") : ""
  );
  const [selectedPrices, setSelectedPrices] = useState({
    minimum: minimumPrice !== null ? minimumPrice : 0,
    maximum: maximumPrice !== 0 ? maximumPrice : null,
  });
  const [selectedCategory, setSelectedCategory] = useState(
    categoryId !== null ? parseInt(categoryId) : 0
  );
  const [selectedManufacturer, setSelectedManufacturer] = useState(
    manufacturerId !== null ? parseInt(manufacturerId) : 0
  );
  const [inStock, setInStock] = useState(
    params.get("inStock") !== null ? JSON.parse(params.get("inStock")) : true
  );
  const [byOrder, setByOrder] = useState(
    params.get("byOrder") !== null ? JSON.parse(params.get("byOrder")) : true
  );
  const [prices, setPrices] = useState([]);
  const [countOfPages, setCountOfPages] = useState(1);

  useEffect(() => {
    const getPrices = async () => {
      await api
        .prices()
        .get()
        .then((res) => setPrices(res.data));
    };
    getPrices();
  }, []);

  useEffect(() => {
    setSpinning(true);
    const getProducts = async () => {
      await api
        .resultProducts()
        .get({
          params: {
            categoryId: selectedCategory,
            manufacturerId: selectedManufacturer,
            searchText: searchedText,
          },
        })
        .then((response) => {
          let filtered = response.data.filter((item) => {
            if (!(item.inStock === inStock) && !byOrder) {
              return false;
            }
            if (!(item.byOrder === byOrder) && !inStock) {
              return false;
            }
            let newPrice = GetPrices(item.id, prices).newPrice;
            if (
              selectedPrices.maximum !== null &&
              newPrice > 0 &&
              selectedPrices.maximum !== 0
            ) {
              if (
                !item.byOrder &&
                (newPrice < Number(selectedPrices.minimum) ||
                  newPrice > Number(selectedPrices.maximum))
              ) {
                return false;
              }
            }
            return true;
          });
          setProducts(filtered);
        })
        .then(
          await api
            .images()
            .get()
            .then((response) => setImages(response.data))
        )
        .finally(setSpinning(false));
    };
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(data);
      setSubCategories(
        data.filter((x) => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
    };
    const getManufacturers = async () => {
      const { data } = await api.categoryManufacturer(selectedCategory).get();
      setManufacturers(data);
    };
    const getCountOfProducts = async () => {
      const { data } = await api.getCountOfProducts().get({
        params: {
          categoryId: selectedCategory,
          manufacturerId: selectedManufacturer,
          searchText: searchedText,
        },
      });
      setCountOfPages(data);
    };
    getProducts();
    getCategories();
    getManufacturers();
    getCountOfProducts();
  }, [
    categoryId,
    manufacturerId,
    selectedCategory,
    selectedManufacturer,
    selectedPrices.maximum,
    selectedPrices.minimum,
    searchedText,
    inStock,
    byOrder,
    prices,
    countOfPages,
  ]);

  return {
    products,
    images,
    categories,
    subCategories,
    manufacturers,
    spinning,
    categoryId,
    manufacturerId,
    setSelectedCategory,
    setSelectedManufacturer,
    setSelectedPrices,
    setSearchedText,
    setInStock,
    setByOrder,
    prices,
    countOfPages,
  };
};
const Result = () => {
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const {
    products,
    images,
    categories,
    subCategories,
    manufacturers,
    spinning,
    categoryId,
    manufacturerId,
    setSelectedCategory,
    setSelectedManufacturer,
    setSelectedPrices,
    setSearchedText,
    setInStock,
    setByOrder,
    prices,
  } = FetchProducts();
  const handleSubmit = () => {};
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setSearchedText("");
    history.push("/result");
  };
  const handleManufacturerChange = (value) => {
    setSelectedManufacturer(value);
    setSearchedText("");
    history.push("/result");
  };
  const handleMaximumPriceChange = (value) => {
    setSelectedPrices((selectedPrices) => ({
      ...selectedPrices,
      maximum: Number(value),
    }));
    setSearchedText("");
  };
  const handleMinimumPriceChange = (value) => {
    setSelectedPrices((selectedPrices) => ({
      ...selectedPrices,
      minimum: Number(value),
    }));
    setSearchedText("");
  };

  const handleInStockBoxChange = (e) => {
    setInStock(e.target.checked);
  };

  const handleByOrderBoxChange = (e) => {
    setByOrder(e.target.checked);
  };

  return (
    <Layout>
      <NavBar
        from="ResultPage"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <SEO
        pageProps={{
          title:
            "IT Plaza LLC - Online supermarket of computers and accessories. Free delivery. Dealer sales.",
          thumbnail: "",
          url: window.location,
        }}
      />
      <Content style={{ margin: "24px 24px", maxWidth: "100%" }}>
        <Row type="flex" justify="center" gutter={16} align="top">
          <Card
            bordered={false}
            title={t("Result.Filter")}
            style={{ width: "100%" }}
          >
            <Form
              layout="inline"
              onSubmit={handleSubmit}
              style={{ textAlign: "center" }}
            >
              <Form.Item label={t("Result.Category")}>
                <Select
                  name="categoryId"
                  defaultValue={categoryId !== null ? parseInt(categoryId) : 0}
                  style={{ width: 200 }}
                  onChange={handleCategoryChange}
                >
                  <Option value={0}>{t("Global.All")}</Option>
                  {categories.map((cat) =>
                    cat.parentId === 0 &&
                    subCategories.some((x) => x.parentId === cat.id) ? (
                      <OptGroup
                        key={cat.id}
                        label={
                          i18n.language === "en"
                            ? cat.name
                            : cat.translate !== null && cat.translate.name
                            ? cat.translate.name
                            : cat.name
                        }
                      >
                        <Option value={cat.id} key={cat.id}>
                          {t("Global.All")} {" - "}
                          {i18n.language === "en"
                            ? cat.name
                            : cat.translate !== null && cat.translate.name
                            ? cat.translate.name
                            : cat.name}
                        </Option>
                        {subCategories
                          .filter((x) => x.parentId === cat.id)
                          .map((subCat) =>
                            subCategories.filter(
                              (x) => x.parentId === subCat.id
                            ).length > 0 ? (
                              <OptGroup
                                id={subCat.id}
                                key={subCat.id}
                                label={
                                  i18n.language === "en"
                                    ? subCat.name
                                    : subCat.translate !== null &&
                                      subCat.translate.name
                                    ? subCat.translate.name
                                    : subCat.name
                                }
                              >
                                <Option value={subCat.id} key={subCat.id}>
                                  {i18n.language === "en"
                                    ? subCat.name
                                    : subCat.translate !== null &&
                                      subCat.translate.name
                                    ? subCat.translate.name
                                    : subCat.name}
                                </Option>
                                {subCategories
                                  .filter((x) => x.parentId === subCat.id)
                                  .map((child) => (
                                    <Option value={child.id} key={child.id}>
                                      {i18n.language === "en"
                                        ? child.name
                                        : child.translate !== null &&
                                          child.translate.name
                                        ? child.translate.name
                                        : child.name}
                                    </Option>
                                  ))}
                              </OptGroup>
                            ) : (
                              <Option value={subCat.id} key={subCat.id}>
                                {i18n.language === "en"
                                  ? subCat.name
                                  : subCat.translate !== null &&
                                    subCat.translate.name
                                  ? subCat.translate.name
                                  : subCat.name}
                              </Option>
                            )
                          )}
                      </OptGroup>
                    ) : cat.parentId === 0 &&
                      subCategories.filter((x) => x.parentId === cat.id)
                        .length === 0 ? (
                      <Option value={cat.id} key={cat.id}>
                        {i18n.language === "en"
                          ? cat.name
                          : cat.translate !== null && cat.translate.name
                          ? cat.translate.name
                          : cat.name}
                      </Option>
                    ) : (
                      ""
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item label={t("Result.Manufacturer")}>
                <Select
                  showSearch
                  name="manufacturerId"
                  defaultValue={
                    manufacturerId !== null ? parseInt(manufacturerId) : 0
                  }
                  style={{ width: 200 }}
                  onChange={handleManufacturerChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0}>{t("Global.All")}</Option>
                  {manufacturers.map((manufacturer) => (
                    <Option value={manufacturer.id} key={manufacturer.id}>
                      {i18n.language === "en"
                        ? manufacturer.name
                        : manufacturer.translate !== null &&
                          manufacturer.translate.name
                        ? manufacturer.translate.name
                        : manufacturer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t("Result.Price")}>
                <InputGroup compact>
                  <InputNumber
                    min={0}
                    style={{ width: 100, textAlign: "center" }}
                    placeholder={t("Result.Minimum")}
                    onChange={handleMinimumPriceChange}
                  />
                  <Input
                    style={{
                      width: 30,
                      borderLeft: 0,
                      pointerEvents: "none",
                      backgroundColor: "#fff",
                    }}
                    placeholder="~"
                    disabled
                  />
                  <InputNumber
                    min={0}
                    style={{ width: 100, textAlign: "center", borderLeft: 0 }}
                    placeholder={t("Result.Maximum")}
                    onChange={handleMaximumPriceChange}
                  />
                </InputGroup>
              </Form.Item>
              <Form.Item>
                <Checkbox
                  defaultChecked={
                    params.get("inStock") !== null
                      ? JSON.parse(params.get("inStock"))
                      : true
                  }
                  onChange={handleInStockBoxChange}
                >
                  {t("ProductPage.InStock")}
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Checkbox
                  defaultChecked={
                    params.get("byOrder") !== null
                      ? JSON.parse(params.get("byOrder"))
                      : true
                  }
                  onChange={handleByOrderBoxChange}
                >
                  {t("ProductPage.ByOrder")}
                </Checkbox>
              </Form.Item>
            </Form>
          </Card>
        </Row>
        <Divider />
        <div id="products" className="results">
          <Spin spinning={spinning}>
            <Row type="flex" justify="center" gutter={16} align="top">
              <Col xs={{ span: 24 }} lg={{ span: 20 }}>
                {products.length > 0
                  ? products
                      .filter(
                        (x) =>
                          images && images.some((y) => y.productId === x.id)
                      )
                      .map((product) => (
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          lg={{ span: 6 }}
                          style={{ marginBottom: 12 }}
                          key={product.id}
                        >
                          <Link
                            to={{
                              pathname: `/product/${product.slug}`,
                              productProps: {
                                id: product.id,
                              },
                            }}
                          >
                            <div className="manufacturerLogoInProduct">
                              {manufacturers &&
                              manufacturers.some(
                                (x) => x.id === product.manufacturerId
                              ) &&
                              manufacturers.find(
                                (x) => x.id === product.manufacturerId
                              ).imageUrl !== "" &&
                              images !== undefined &&
                              images.some((x) => x.productId === product.id) ? (
                                <img
                                  width="100%"
                                  src={ManufacturerImageUrl(manufacturers, product.manufacturerId)}
                                  alt={
                                    manufacturers.find(
                                      (x) => x.id === product.manufacturerId
                                    ).name
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <Card
                              bordered={false}
                              cover={
                                <img
                                  key={product.id}
                                  alt={product.name}
                                  src={ImageUrl(images, product.id)}
                                />
                              }
                              actions={[
                                <Tag>
                                  {categories &&
                                  categories.some(
                                    (x) => x.id === product.categoryId
                                  )
                                    ? categories.find(
                                        (x) => x.id === product.categoryId
                                      ).name
                                    : ""}
                                </Tag>,
                                <div>
                                  <div className="oldPrice">
                                    {!product.byOrder
                                      ? GetPrices(product.id, prices)
                                          .oldPrice !== 0
                                        ? GetPrices(product.id, prices)
                                            .oldPrice + " ֏"
                                        : ""
                                      : ""}
                                  </div>
                                  <div className="cardPrice">
                                    {!product.byOrder
                                      ? GetPrices(product.id, prices).newPrice +
                                        " ֏"
                                      : t("SelectedProducts.ByOrder")}
                                  </div>
                                </div>,
                              ]}
                            >
                              <Meta title={product.name} />
                            </Card>
                          </Link>
                        </Col>
                      ))
                  : t("Result.NotFound")}
              </Col>
            </Row>
          </Spin>
        </div>
      </Content>
    </Layout>
  );
};

export default Result;
