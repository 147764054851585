import React, { useState, useEffect } from "react";
import {
  Drawer,
  Button,
  List,
  Avatar,
  InputNumber,
  Icon,
  Divider,
  Row,
  Radio
} from "antd";
import Order from "./Order";
import { useTranslation } from "react-i18next";
import iDramLogo from "./../Images/logo_idram.png";
import arcaLogo from "./../Images/arca_logo.png";
import visaLogo from "./../Images/visa_logo.png";
import masterLogo from "./../Images/mastercard_logo.png";
import amexLogo from "./../Images/amex_logo.png";

const Cart = ({ from, cartCount, setCartCount }) => {
  let cartData = [];
  const [byOrderExist, setByOrderExist] = useState(false);
  const { t } = useTranslation();
  
  if (localStorage.getItem("cart")) {
    cartData = JSON.parse(localStorage.getItem("cart"));
  }

  const [cart, setCart] = useState(cart => ({
    ...cart,
    visible: false
  }));
  const [orderDrawer, setOrderDrawer] = useState(orderDrawer => ({
    ...orderDrawer,
    visible: false
  }));
  const [total, setTotal] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [paymentType, setPaymentType] = useState(1);

  useEffect(() => {
    if (cartData.filter(x => x.price === 0).length > 0 || total !== 0) {
      setDisabled(false);
    }
    if (cartData.filter(x => x.price === 0).length === 0 && total === 0) {
      setDisabled(true);
    }
    if (cartData) {
      setTotal(
        cartData.reduce((prev, cur) => prev + cur.price * cur.quantity, 0)
      );
    }
    if(cartData.filter(x => x.price === 0).length > 0) {
      setByOrderExist(true)
    }
    else {
      setByOrderExist(false)
    }
  }, [cartData, total]);

  const showDrawer = () => {
    setCart({
      visible: true
    });
  };

  const onClose = () => {
    setCart({
      visible: false
    });
  };

  const showOrderDrawer = () => {
    setOrderDrawer({
      visible: true
    });
  };

  const closeOrderDrawer = () => {
    setOrderDrawer({
      visible: false
    });
  };

  const handleCartDelete = id => {
    cartData.forEach((item, index) => {
      if (item.id === id) {
        cartData.splice(index, 1);
      }
      setCartCount(cartData.length);
      if(cartData.filter(x => x.price === 0).length > 0) {
        setByOrderExist(true)
      }
      else {
        setByOrderExist(false)
      }
      localStorage.setItem("cart", JSON.stringify(cartData));
    });
  };

  const onQuantityChange = (id, value) => {
    cartData = JSON.parse(localStorage.getItem("cart"));
    cartData.forEach(item => {
      if (item.id === id) {
        item.quantity = value;
      }
      if(cartData.filter(x => x.price === 0).length > 0) {
        setByOrderExist(true)
      }
      else {
        setByOrderExist(false)
      }
    });

    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  const onPaymentTypeChange = (e) => {
    setPaymentType(Number(e.target.value));
    //console.log(e.target.value);
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <div>
      <Button type="default" className="cart" shape="round" icon="shopping-cart" onClick={showDrawer}>
        <strong>
          ({from === "ProductPage" ? cartCount : cartData.length})
        </strong>
      </Button>
      <Drawer
        title={t("Cart.CartTitle")}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={cart.visible}
        width={window.innerWidth < 576 ? "100%" : "55%"}
      >
        <List
          itemLayout="horizontal"
          dataSource={cartData}
          renderItem={item => (
            <List.Item
              actions={[
                <InputNumber
                  min={1}
                  max={999}
                  value={item.quantity}
                  onChange={e => onQuantityChange(item.id, e)}
                />,
                <Button
                  type="link"
                  onClick={e => handleCartDelete(item.id, e)}
                  style={{ marginLeft: 10 }}
                >
                  <Icon type="delete" />
                </Button>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.imageUrl.thumbnail} />}
                title={<a href={item.url}>{item.name}</a>}
              />
              <div className="cartPrice">{item.price !== 0 ? item.price +' ֏' : t("Cart.ByOrder")}</div>
            </List.Item>
          )}
        />
        <Divider dashed />
            <p className="additionalText">* {t("Order.DeliveryInfo")}</p>
            <div className="totalPrice">{t("Cart.Total")}: {total} ֏ {byOrderExist ? <span>*</span> : ''}</div>
            <div className="additionalText">{byOrderExist ? '* '+t("Cart.AdditionalText") : ''}</div>
        <Divider dashed />
        <Row type="flex" justify="center" align="middle">
        <Radio.Group onChange={onPaymentTypeChange} value={paymentType} style={{width: "100%"}}>
        <Radio style={radioStyle} value={1}>
          {t("Order.PayOnDelivery")}
        </Radio>
          <Radio style={radioStyle} value={2}>
          {t("Order.PayWithIDram")}
          <img style={{marginLeft: 6, width: "80px"}} width="80px" src={iDramLogo} alt="idram-logo"/>
        </Radio>
        <Radio style={radioStyle} value={3}>
        {t("Order.PayWithCard")}
          <img style={{marginLeft: 6, width: "40px"}} width="40px" src={arcaLogo} alt="arca-logo" />
          <img style={{marginLeft: 6, width: "40px"}} width="40px" src={visaLogo} alt="visa-logo" />
          <img style={{marginLeft: 6, width: "40px"}} width="40px" src={masterLogo} alt="master-logo" />
          <img style={{marginLeft: 6, width: "40px"}} width="40px" src={amexLogo} alt="amex-logo" />
        </Radio>
      </Radio.Group>
      </Row>
      <Divider dashed />
      <Row type="flex" justify="center" align="middle">
          <Button
            size="large"
            type="primary"
            disabled={disabled}
            onClick={showOrderDrawer}
          >
            {t("Cart.OrderNow")}
          </Button>
        </Row>
        <Drawer
          title={t("Cart.CartTitle")}
          width={window.innerWidth < 576 ? "100%" : "50%"}
          closable={true}
          onClose={closeOrderDrawer}
          visible={orderDrawer.visible}
        >
          <Order
            cartData={cartData}
            total={total}
            setCartCount={setCartCount}
            setCart={setCart}
            paymentType={paymentType}
          />
        </Drawer>
      </Drawer>
    </div>
  );
};
export default Cart;
