import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const MainCarousel = ({setLoadingAnimation}) => {
  const [banners, setBanners] = useState();

  useEffect(() => {
    const getBanners = async () => {
      const imagesUrls = [];
      await axios.get(`${API_URL}/images/`).then((response) =>
        response.data.forEach((img) => {
          if (img.bannerName.includes("carouselBanner")) {
            imagesUrls.push({
              bannerName: img.bannerName,
              url: `${API_URL}/${img.url}`,
              additionalLink: img.additionalLink,
            });
          }
        })
      );
      setBanners(imagesUrls);
    };
    getBanners();
  }, []);

  return (
    <Carousel autoplaySpeed={5000} autoplay>
      {banners &&
        banners.length > 0 &&
        banners.map((el, index) => {
          return (
            <div key={index} className="firstSlide">
              <a href={el.additionalLink}>
                <img onLoad={() => setLoadingAnimation(false)} alt={el.bannerName} src={el.url} />
              </a>
            </div>
          );
        })}
    </Carousel>
  );
};

export default MainCarousel;