import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import { Row, Card, Typography, Col, Tag } from "antd";
import { useTranslation } from "react-i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { GetPrices, ImageUrl, ManufacturerImageUrl } from "./../Common";

const { Title } = Typography;
const { Meta } = Card;

const FetchProducts = (categoryId, exceptId) => {
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState();
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => setProducts(response.data))
        .then(
          await api
            .images()
            .get()
            .then((response) => setImages(response.data))
        );
    };
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(data);
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };
    const getPrices = async () => {
      await api
        .prices()
        .get()
        .then((res) => setPrices(res.data));
    };
    getProducts();
    getCategories();
    getManufacturers();
    getPrices();
  }, [categoryId, exceptId]);
  return {
    products,
    images,
    categories,
    manufacturers,
    prices,
  };
};

const Relations = ({ categoryId, exceptId }) => {
  const { products, images, categories, manufacturers, prices } = FetchProducts(
    categoryId,
    exceptId
  );

  const { t } = useTranslation();

  const relationsQuantity = () => {
    return products.filter(
      (x) =>
        x.isActive &&
        x.categoryId === categoryId &&
        x.id !== exceptId &&
        images &&
        images.filter((y) => y.productId === x.id).length > 0
    ).length;
  };
  const GetFilteredProducts = (forFilter) => {
    if (forFilter.length > 24) {
      return forFilter.splice(0, 23)
    }
    else {
      return forFilter;
    }
  };

  return (
    <div id="products">
      <div
        style={{
          margin: "24px 16px",
          padding: 24,
          background: "#fff",
          borderRadius: 12,
        }}
      >
        {relationsQuantity() > 0 ? (
          <>
            <Title level={3}>{t("Relations.Title")}</Title>
            <Row type="flex" justify="center" gutter={16} align="top">
              <Col xs={{ span: 22 }} lg={{ span: 22 }}>
                <AliceCarousel
                  responsive={{
                    0: {
                      items: 1,
                    },
                    1024: { items: 4 },
                  }}
                  mouseTrackingEnabled
                  touchTrackingEnabled
                  infinite={false}
                >
                  {GetFilteredProducts(products
                    .filter(
                      (x) =>
                        x.isActive &&
                        x.categoryId === categoryId &&
                        x.id !== exceptId &&
                        images &&
                        images.filter((y) => y.productId === x.id).length > 0
                    ))
                    .map((product, index) => (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        lg={{
                          span:
                            relationsQuantity() === 1
                              ? 6
                              : relationsQuantity() === 2
                              ? 12
                              : 24,
                        }}
                        style={{ marginBottom: 12 }}
                        key={product.id}
                      >
                        <Link
                          to={{
                            pathname: `/product/${product.slug}`,
                            productProps: {
                              id: product.id,
                            },
                            hash: "#product",
                            from: { fromRelations: true },
                          }}
                        >
                          <div className="manufacturerLogoInProduct">
                            {manufacturers &&
                            manufacturers.filter(
                              (x) => x.id === product.manufacturerId
                            ).length > 0 &&
                            manufacturers.filter(
                              (x) => x.id === product.manufacturerId
                            )[0].imageUrl !== "" &&
                            images !== undefined &&
                            images.filter((x) => x.productId === product.id)
                              .length > 0 ? (
                              <img
                                width="100%"
                                src={ManufacturerImageUrl(manufacturers, product.manufacturerId)}
                                alt={
                                  manufacturers.filter(
                                    (x) => x.id === product.manufacturerId
                                  )[0].name
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <Card
                            bordered={false}
                            cover={
                              <img
                                key={product.id}
                                alt={product.name}
                                src={ImageUrl(images, product.id)}
                              />
                            }
                            actions={[
                              <Tag>
                                {categories &&
                                categories.filter(
                                  (x) => x.id === product.categoryId
                                ).length > 0
                                  ? categories.filter(
                                      (x) => x.id === product.categoryId
                                    )[0].name
                                  : ""}
                              </Tag>,
                              <div>
                                <div className="oldPrice">
                                  {!product.byOrder
                                    ? GetPrices(product.id, prices).oldPrice !==
                                      0
                                      ? GetPrices(product.id, prices).oldPrice +
                                        " ֏"
                                      : ""
                                    : ""}
                                </div>
                                <div className="cardPrice">
                                  {!product.byOrder
                                    ? GetPrices(product.id, prices).newPrice +
                                      " ֏"
                                    : t("SelectedProducts.ByOrder")}
                                </div>
                              </div>,
                            ]}
                          >
                            <Meta title={product.name} />
                          </Card>
                        </Link>
                      </Col>
                    ))}
                </AliceCarousel>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Title level={3}>{t("SelectedProducts.1")}</Title>
        <Row type="flex" justify="center" gutter={16} align="top">
          <Col xs={{ span: 22 }} lg={{ span: 22 }}>
            <AliceCarousel
              responsive={{
                0: {
                  items: 1,
                },
                1024: { items: 4 },
              }}
              mouseTrackingEnabled
              touchTrackingEnabled
              infinite={false}
            >
              {products
                .filter(
                  (x) =>
                    x.favorite &&
                    x.isActive &&
                    images &&
                    images.some((y) => y.productId === x.id)
                )
                .map((product) => (
                  <Col
                    xs={{ span: 24 }}
                    lg={{ span: 24 }}
                    style={{ marginBottom: 12 }}
                    key={product.id}
                  >
                    <Link
                      to={{
                        pathname: `/product/${product.slug}`,
                        productProps: {
                          id: product.id,
                        },
                        hash: "#product",
                        from: { fromRelations: true },
                      }}
                    >
                      <div className="manufacturerLogoInProduct">
                        {manufacturers &&
                        manufacturers.some(
                          (x) => x.id === product.manufacturerId
                        ) &&
                        manufacturers.find(
                          (x) => x.id === product.manufacturerId
                        ).imageUrl !== "" &&
                        images !== undefined &&
                        images.some((x) => x.productId === product.id) ? (
                          <img
                            width="100%"
                            src={ManufacturerImageUrl(manufacturers, product.manufacturerId)}
                            alt={
                              manufacturers.find(
                                (x) => x.id === product.manufacturerId
                              ).name
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <Card
                        bordered={false}
                        cover={
                          <img
                            className="cardImgSkeleton"
                            key={product.id}
                            alt={product.name}
                            src={ImageUrl(images, product.id)}
                          />
                        }
                        actions={[
                          <Tag>
                            {categories &&
                            categories.filter(
                              (x) => x.id === product.categoryId
                            ).length > 0
                              ? categories.filter(
                                  (x) => x.id === product.categoryId
                                )[0].name
                              : ""}
                          </Tag>,
                          <div>
                            <div className="oldPrice">
                              {!product.byOrder
                                ? GetPrices(product.id, prices).oldPrice !== 0
                                  ? GetPrices(product.id, prices).oldPrice +
                                    " ֏"
                                  : ""
                                : ""}
                            </div>
                            <div className="cardPrice">
                              {!product.byOrder
                                ? GetPrices(product.id, prices).newPrice + " ֏"
                                : t("SelectedProducts.ByOrder")}
                            </div>
                          </div>,
                        ]}
                      >
                        <Meta title={product.name} />
                      </Card>
                    </Link>
                  </Col>
                ))}
            </AliceCarousel>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Relations;
