import React, { useState, useEffect } from "react";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import api from "./../Api";
import {
  Typography,
  Row,
  Col,
  Table,
  Layout,
  Button,
  InputNumber,
  Divider,
  message,
  Spin,
  Tag,
  Card,
} from "antd";
import NavBar from "../Components/NavBar";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Relations from "./Relations";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  VKShareButton,
  ViberShareButton,
  EmailShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTelegram,
  faTwitter,
  faWhatsapp,
  faVk,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
import ResultForm from "../Components/ResultForm";
import SEO from "../Components/SEO";
import { GetPrices, ManufacturerImageUrlFromObject } from "./../Common";
import ReactImageMagnify from "react-image-magnify";
import ReactSlick from "react-slick";

const { Title, Paragraph } = Typography;
const API_URL = process.env.REACT_APP_API_URL;
const { Content } = Layout;

//get categories and manufacturers
const CategoryTag = ({ catId, manufId }) => {
  const [categories, setCategories] = useState();
  const [manufacturers, setManufacturers] = useState({});

  useEffect(() => {
    if (catId !== undefined) {
      const getCategories = async () => {
        const { data } = await api.categories().get();
        setCategories(
          data && data.some((x) => x.id === catId)
            ? data.find((x) => x.id === catId).name
            : ""
        );
      };
      getCategories();
    }
    if (manufId !== undefined) {
      const getManufacturers = async () => {
        const { data } = await api.manufacturers(manufId).get();
        setManufacturers(
          data && data.some((x) => x.id === manufId)
            ? {
                name: data.find((x) => x.id === manufId).name,
                imageUrl: data.find((x) => x.id === manufId).imageUrl,
              }
            : ""
        );
      };
      getManufacturers();
    }
  }, [catId, manufId]);

  return (
    <Row type="flex" justify="space-between" align="middle">
      <div>
        <Tag className="productTags" color="#faa535">
          {categories}
        </Tag>
        <Tag className="productTags" color="#001529">
          {manufacturers.name}
        </Tag>
      </div>
      <div className="manufacturerLogoInProductPage">
        {manufacturers && manufacturers.imageUrl !== "" ? (
          <img
            width="100%"
            src={ManufacturerImageUrlFromObject(manufacturers)}
            alt={manufacturers.name}
          />
        ) : (
          ""
        )}
      </div>
    </Row>
  );
};

const BottomBanner = () => {
  const [banners, setBanners] = useState();

  useEffect(() => {
    const getBanners = async () => {
      const imagesUrls = [];
      await api
        .images()
        .get()
        .then((response) =>
          response.data.forEach((img) => {
            if (img.bannerName === "bottomBanner") {
              imagesUrls.push({
                bannerName: img.bannerName,
                url: `${API_URL}/${img.url}`,
                additionalLink: img.additionalLink,
              });
            }
          })
        );
      setBanners(imagesUrls);
    };
    getBanners();
  }, []);
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={18}>
        {banners &&
        banners.length > 0 &&
        banners.some((x) => x.bannerName === "bottomBanner") ? (
          <a
            href={
              banners.find((x) => x.bannerName === "bottomBanner")
                .additionalLink
            }
          >
            <img
              src={banners.find((x) => x.bannerName === "bottomBanner").url}
              alt="bottomBanner"
              className="bottomBanner"
            />
          </a>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

const Images = (product) => {
  const [images, setImages] = useState([]);
  const [carousel, setCarousel] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const imagesUrls = [];
      const carousels = [];
      await api
        .images()
        .get()
        .then((response) =>
          response.data
            .filter((x) => x.productId === product)
            .forEach((image) => {
              imagesUrls.push({
                original: `${API_URL}/${image.url}`,
                thumbnail: `${API_URL}/${image.url}`,
              });
              carousels.push({ src: `${API_URL}/${image.url}` });
            })
        )
        .then();
      setImages((images) => ({ ...images, fileList: imagesUrls }));
      setCarousel(carousels);
      window.scrollTo(0, 0);
    };
    fetchImages();
  }, [product]);
  return { images, carousel };
};

const Specs = (productFeatures) => {
  const [comparedArray, setComparedArray] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchFeatures = async () => {
      let currentProductFeatures = [];
      let intersection = [];
      if (productFeatures && productFeatures !== null) {
        currentProductFeatures = productFeatures;
      }
      await api
        .specs()
        .get()
        .then((response) =>
          response.data.forEach((e1) =>
            currentProductFeatures.forEach((e2, index) => {
              if (e1.id === e2.selected) {
                intersection.push({
                  id: index,
                  name: i18n.language === "en" ? e1.name : e1.translate,
                  description: e2.description,
                });
              }
            })
          )
        );
      setComparedArray(intersection);
      window.scrollTo(0, 0);
    };
    fetchFeatures();
  }, [productFeatures, i18n.language]);
  return comparedArray;
};

const Product = () => {
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const getPrices = async () => {
      await api
        .prices()
        .get()
        .then((res) => setPrices(res.data));
    };
    getPrices();
  }, []);

  let cartData = [];
  let location = useLocation();
  const match = useRouteMatch("/product/:slug");
  const { t, i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const fetchProducts = async () => {
      await api
        .products(match.params.slug)
        .get()
        .then(async (response) => {
          if (response.data.length === 0) {
            history.push("/404");
          } else {
            setProduct(response.data[0]);
          }
        });
      setLoading(false);
    };
    fetchProducts();
  }, [history, match.params.slug]);

  const { images, carousel } = Images(product.id);
  const comparedArray = Specs(product.features);
  const columns = [
    {
      title: `${t("ProductPage.FeatureName")}`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `${t("ProductPage.FeatureDescription")}`,
      dataIndex: "description",
      key: "description",
    },
  ];

  const onQuantityChange = (value) => {
    setQuantity(value);
  };

  const handleAddToCart = async () => {
    if (localStorage.getItem("cart")) {
      cartData = JSON.parse(localStorage.getItem("cart"));
      if (cartData.length === 0) {
        cartData.push({
          id: product.id,
          code: product.code,
          name: product.name,
          quantity: quantity,
          url: location.pathname,
          imageUrl: images.fileList.length > 0 ? images.fileList[0] : "",
          price: product.byOrder ? 0 : GetPrices(product.id, prices).newPrice,
        });
      } else {
        cartData.forEach((item) => {
          if (item.id === product.id) {
            item.quantity += quantity;
          } else if (cartData.filter((x) => x.id === product.id).length === 0) {
            cartData.push({
              id: product.id,
              code: product.code,
              name: product.name,
              quantity: quantity,
              url: location.pathname,
              imageUrl: images.fileList.length > 0 ? images.fileList[0] : "",
              price: product.byOrder
                ? 0
                : GetPrices(product.id, prices).newPrice,
            });
            localStorage.setItem("cart", JSON.stringify(cartData));
          }
        });
      }
      localStorage.setItem("cart", JSON.stringify(cartData));
    } else {
      cartData.push({
        id: product.id,
        code: product.code,
        name: product.name,
        quantity: quantity,
        url: location.pathname,
        imageUrl: images.fileList.length > 0 ? images.fileList[0] : "",
        price: product.byOrder ? 0 : GetPrices(product.id, prices).newPrice,
      });
      localStorage.setItem("cart", JSON.stringify(cartData));
    }
    setCartCount(cartData.length);
    await message.success(`${t("ProductPage.CartUpdated")}`, 4);
  };

  const rimProps = {
    enlargedImagePortalId: "portal",
    enlargedImageContainerDimensions: {
      width: "100%",
      height: "100%",
    },
  };

  const customPaging = (i) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <img alt="img" src={carousel[i].src} />
      </div>
    );
  };

  return (
    <Spin spinning={loading}>
      <div id="product">
        <Layout>
          <NavBar
            from="ProductPage"
            cartCount={cartCount}
            setCartCount={setCartCount}
          />
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              background: "#fff",
              minHeight: "100vh",
              borderRadius: 12,
            }}
          >
            <ResultForm />
            <SEO
              pageProps={{
                title: product.name,
                thumbnail:
                  images && images.fileList && images.fileList.length > 0
                    ? images.fileList[0].thumbnail
                    : "",
                url: window.location,
              }}
            />
            <Row type="flex" justify="center" gutter={32} align="top">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <ReactSlick
                  arrows={true}
                  dots={true}
                  infinite={true}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  customPaging={customPaging}
                  dotsClass="slick-dots slick-thumb"
                >
                  {carousel &&
                    carousel.map((src, index) => (
                      <div key={index}>
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Wristwatch by Versace",
                              isFluidWidth: true,
                              src: src.src,
                            },
                            largeImage: {
                              src: src.src,
                              width: 1426,
                              height: 1426,
                            },
                            lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                          }}
                          {...rimProps}
                        />
                      </div>
                    ))}
                </ReactSlick>
                {product.youtube ? (
                  <iframe
                    title="youtube"
                    src={product.youtube}
                    frameBorder="0"
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} className="col-mobile">
                <div id="portal"></div>
                <Typography>
                  <Title>{product.name}</Title>
                  <CategoryTag
                    catId={product.categoryId}
                    manufId={product.manufacturerId}
                  />
                  <Divider />
                  <Row type="flex" justify="space-between" align="middle">
                    <div>
                      <Title level={4} className="oldPrice">
                        {!product.byOrder
                          ? GetPrices(product.id, prices).oldPrice !== 0
                            ? GetPrices(product.id, prices).oldPrice + " ֏"
                            : ""
                          : ""}
                      </Title>
                      <Title level={2}>
                        {!product.byOrder
                          ? GetPrices(product.id, prices).newPrice + " ֏"
                          : t("SelectedProducts.ByOrder")}
                      </Title>
                    </div>
                    <div>
                      {product.inStock ? (
                        <Tag color="green">{t("ProductPage.InStock")}</Tag>
                      ) : (
                        <Tag color="red">{t("ProductPage.OutOfStock")}</Tag>
                      )}
                    </div>
                  </Row>
                  <Paragraph>
                    {i18n.language === "en"
                      ? ReactHtmlParser(product.description)
                      : product.translate !== null && product.translate
                      ? ReactHtmlParser(product.translate)
                      : ReactHtmlParser(product.description)}
                  </Paragraph>
                  <Table
                    dataSource={
                      product.id
                        ? comparedArray.sort((x, y) => (x.id > y.id ? 1 : -1))
                        : []
                    }
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    bordered={false}
                  />
                </Typography>
                <Divider dashed />
                <Row type="flex" justify="center" gutter={32} align="middle">
                  <Col>
                    <InputNumber
                      min={1}
                      max={999}
                      defaultValue={1}
                      onChange={onQuantityChange}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      icon="shopping-cart"
                      size="large"
                      disabled={!product.inStock}
                      onClick={handleAddToCart}
                    >
                      {t("ProductPage.AddToCart")}
                    </Button>
                  </Col>
                </Row>
                <Row
                  className="share_buttons"
                  type="flex"
                  justify="center"
                  gutter={32}
                  align="middle"
                >
                  <Card bordered={false} title={t("ProductPage.Share")}>
                    <FacebookShareButton
                      children={<FontAwesomeIcon icon={faFacebook} />}
                      url={window.location.href}
                    />
                    <TwitterShareButton
                      children={<FontAwesomeIcon icon={faTwitter} />}
                      url={window.location.href}
                    />
                    <TelegramShareButton
                      children={<FontAwesomeIcon icon={faTelegram} />}
                      url={window.location.href}
                    />
                    <WhatsappShareButton
                      children={<FontAwesomeIcon icon={faWhatsapp} />}
                      url={window.location.href}
                    />
                    <VKShareButton
                      children={<FontAwesomeIcon icon={faVk} />}
                      url={window.location.href}
                    />
                    <ViberShareButton
                      children={<FontAwesomeIcon icon={faViber} />}
                      url={window.location.href}
                    />
                    <EmailShareButton
                      children={<FontAwesomeIcon icon={faEnvelope} />}
                      url={window.location.href}
                    />
                  </Card>
                </Row>
              </Col>
            </Row>
          </Content>
          <Divider />
          <Row type="flex" justify="center" align="middle">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Relations
                categoryId={product.categoryId}
                exceptId={product.id}
              />
              <Divider />
              <BottomBanner />
            </Col>
          </Row>
        </Layout>
      </div>
    </Spin>
  );
};

export default Product;
