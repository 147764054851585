import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import { Row, Col, Modal } from "antd";
import { useTranslation } from "react-i18next";
import logo from "../Images/logoinvert.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faSkype,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
import packageJson from "./../../package.json";

const FooterBar = () => {
  const [pages, setPages] = useState();
  const [information, setInformation] = useState();
  const [modal, setModal] = useState({ visible: false });
  const { t, i18n } = useTranslation();

  useEffect(() => {

    const getPages = async () => {
      await api
        .pages()
        .get()
        .then((response) =>
          setPages(response.data.filter((x) => x.isInNavigation))
        );
    };
    const getInformation = async () => {
      await api
        .informations()
        .get()
        .then((response) => setInformation(response.data));
    };
    getPages();
    getInformation();
  }, []);

  const showModal = () => {
    setModal({
      visible: true,
    });
  };

  const handleOk = (e) => {
    console.log(e);
    setModal({
      visible: false,
    });
  };

  const handleCancel = (e) => {
    console.log(e);
    setModal({
      visible: false,
    });
  };

  return (
    <div className="footer">
      <Row type="flex" justify="space-between" className="stretch">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          lg={{ span: 6 }}
          className="footerCol"
        >
          <ul className="footerMenu">
            <Link to="/">
              <div className="footerLogo">
                <img src={logo} alt="logo" />
              </div>
            </Link>
            {pages &&
              pages.map((page) => (
                <Link key={page.id} to={`/page/${page.slug}`}>
                  <li>
                    {i18n.language === "en"
                      ? page.title
                      : page.translate !== null && page.translate
                      ? page.translate.title
                      : page.title}
                  </li>
                </Link>
              ))}
          </ul>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          lg={{ span: 6 }}
          className="footerCol"
        >
          <div>
            {information &&
              information.map((info, index) => (
                <div key={index}>
                  <div className="footerMenu">
                    <div
                      onClick={showModal}
                      style={{ cursor: "pointer" }}
                      className="footerMenu"
                    >
                      <FontAwesomeIcon icon={faMapMarker} />
                      {t("Footer.FindUs")}
                    </div>
                    <Modal
                      title={t("Footer.ContactUs")}
                      visible={modal.visible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      width="80%"
                      style={{ margin: "auto" }}
                    >
                      <iframe
                        title="Google maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d294.9010392329799!2d44.50315966528448!3d40.17671848898895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd95aea474b3%3A0x33fa1036643c5a0c!2sITPlaza!5e0!3m2!1sru!2s!4v1581406229734!5m2!1sru!2s"
                        frameborder="0"
                        style={{ border: 0, height: 450 }}
                        allowfullscreen=""
                      ></iframe>
                    </Modal>
                  </div>
                  <p>{info.address1}</p>
                  <p>{info.address2}</p>
                </div>
              ))}
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          lg={{ span: 6 }}
          className="footerCol"
        >
          <div className="footerMenu"> {t("Footer.SocialMedia")}</div>
          <div className="socialIcons">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/itPlazaOnlineStore/"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
              <p>Facebook</p>
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/itplaza.am/"
            >
              <FontAwesomeIcon icon={faInstagram} />
              <p>Instagram</p>
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UCRYRHf6MfRouXfBlBN-9eww/featured"
            >
              <FontAwesomeIcon icon={faYoutube} />
              <p>YouTube</p>
            </a>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
          <div className="footerSocial">
            {information &&
              information.map((info, index) => (
                <div key={index}>
                  <div className="footerMenu"> {t("Footer.ContactUs")}</div>
                  <div className="findUs">
                    <div className="footerPhones">
                      <p>{info.phone1}</p>
                      <p>{info.phone2}</p>
                      <p>{info.mail}</p>
                    </div>
                    <div className="socialIcons">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://api.whatsapp.com/send?phone=37499906030"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} />
                        <p>WhatsApp</p>
                      </a>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="skype:Marketing.itplaza?call"
                      >
                        <FontAwesomeIcon icon={faSkype} />
                        <p>Skype</p>
                      </a>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="viber://contact?number=%2B37499906030"
                      >
                        <FontAwesomeIcon icon={faViber} />
                        <p>Viber</p>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <p
          style={{
            textAlign: "center",
            color: "white",
            fontWeight: 600,
            margin: "16px auto",
          }}
        >
          ITPlaza © 2019 - {new Date().getFullYear()} {packageJson.name}{" "}
          v{packageJson.version} <br />
          Created by
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://owltechagency.com/"
          >
            &nbsp;Owltech Agency
          </a>
        </p>
      </Row>
    </div>
  );
};
export default FooterBar;
