export const GetPrices = (productId, prices) => {
  let productPrice = prices.find((x) => x.id === productId);
  let oldPrice = 0;
  let newPrice = 0;

  if (productPrice !== undefined && productPrice.price) {
    if (productPrice.price.length > 1) {
      oldPrice = productPrice.price[0];
      newPrice = productPrice.price[1];
    }
    if (productPrice.price.length === 1) {
      newPrice = productPrice.price[0];
    }
  }
  return {
    oldPrice,
    newPrice,
  };
};

const API_URL = process.env.REACT_APP_API_URL;

export const ImageUrl = (images, productId) => {
  let imageUrl = "";
  if (images.some((x) => x.productId === productId && x.isDefault)) {
    let filePath =
      images
        .find((x) => x.productId === productId && x.isDefault)
        .url.replace(/\.[^/.]+$/, "") + "x200.webp";
    imageUrl = API_URL + "/" + filePath;
  } else {
    let filePath =
      images
        .find((x) => x.productId === productId)
        .url.replace(/\.[^/.]+$/, "") + "x200.webp";
    imageUrl = API_URL + "/" + filePath;
  }

  return imageUrl;
};

export const ManufacturerImageUrl = (manufacturers, manufacturerId) => {
  let filePath =
    manufacturers
      .find((x) => x.id === manufacturerId)
      .imageUrl.replace(/\.[^/.]+$/, "") + "x200.webp";
  return filePath;
};

export const ManufacturerImageUrlFromObject = (manufacturer) => {
  if (manufacturer && manufacturer.imageUrl) {
    return manufacturer.imageUrl.replace(/\.[^/.]+$/, "") + "x200.webp";
  } else {
    return "";
  }
};
