import React, { useState, useEffect } from "react";
import api from "./../Api";
import { Form, Input, Select, Button, Row, Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const OrderUI = (props) => {
  const [orderState, setOrderState] = useState(props);
  const [inputs, setInputs] = useState({});
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [geoZones, setGeoZones] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [orderNum, setOrderNum] = useState(0);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const fetchGeoZones = async () => {
    const { data } = await api.geoZones().get();
    setGeoZones(data);
  };

  useEffect(() => {
    fetchGeoZones();
  }, []);

  const regions = [
    { name: "Lori", value: "lori", translate: "Լոռի" },
    { name: "Shirak", value: "shirak", translate: "Շիրակ" },
    { name: "Tavush", value: "tavush", translate: "Տավուշ" },
    { name: "Aragatsotn", value: "aragatsotn", translate: "Արագածոտն" },
    { name: "Kotayk", value: "kotayk", translate: "Կոտայք" },
    { name: "Armavir", value: "armavir", translate: "Արմավիր" },
    { name: "Yerevan", value: "yerevan", translate: "Երևան" },
    { name: "Ararat", value: "ararat", translate: "Արարատ" },
    { name: "Gegharkunik", value: "gegharkunik", translate: "Գեղարքունիք" },
    { name: "Vayots Dzor", value: "vayotsDzor", translate: "Վայոց Ձոր" },
    { name: "Syunik", value: "syunik", translate: "Սյունիք" },
  ];

  useEffect(() => {
    setOrderState(props);
  }, [props]);

  const { getFieldDecorator } = orderState.form;
  const prefixSelector = getFieldDecorator("prefix", {
    initialValue: "+374",
  })(
    <Select style={{ width: 70 }}>
      <Option value="+374">+374</Option>
    </Select>
  );

  useEffect(() => {
    setCustomer((customer) => ({
      ...customer,
      name: inputs.firstName ?? "",
      lastName: inputs.lastName ?? "",
      region: inputs.region ?? "",
      area: inputs.area ?? "",
      address: inputs.address ?? "",
      mail: inputs.mail ?? "",
      phone: inputs.phone ?? "",
      secondPhone: inputs.secondPhone ?? "",
    }));
  }, [inputs]);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    orderState.form.validateFields(async (err, values) => {
      let tmpOrderNum = 0;
      if (err === null) {
        setLoading(true);
        props.setCartCount(0);
        props.setCart(false);
        const data = {
          customer: customer,
          notes: inputs.notes ?? "",
          products: props.cartData,
          deliveryPrice: deliveryPrice,
          totalAmount: Number(props.total) + deliveryPrice,
          date: new Date(),
          paymentStatus: "pending",
          fulfillmentStatus: "pending",
          paymentType: props.paymentType
        };
        await api
          .orders()
          .post(data)
          .then(async (response) => {
            setOrderNum(response.data.id);
            tmpOrderNum = response.data.id;
            if (localStorage.getItem("cart")) {
              localStorage.removeItem("cart");
            }
            setInputs({});
            if (props.paymentType === 1) {
              history.push("/success-payment");
            }
            //iDram provider
            else if (props.paymentType === 2) {
              let paymentData = {
                EDP_LANGUAGE: i18n.language.toUpperCase(),
                EDP_DESCRIPTION: inputs.notes ?? "",
                EDP_AMOUNT: Number(props.total) + deliveryPrice,
                EDP_BILL_NO: tmpOrderNum,
              };
              await api
                .iDramPaymentProvider()
                .post(paymentData)
                .then((res) => {
                  paymentData = { ...paymentData, EDP_REC_ACCOUNT: res.data };
                  document.getElementById("idram-form").submit();
                  //api
                  //.iDramGateway()
                  //.post(paymentData)
                })
                .catch((error) => console.log(error));
            }
            //acbaProvider
            else if (props.paymentType === 3) {
              const paymentData = {
                amount: Number(props.total) + deliveryPrice,
                returnUrl:
                  process.env.REACT_APP_API_URL === "http://localhost:3000/api"
                    ? "http://localhost:3001/order-details"
                    : "https://itplaza.am/order-details",
                pageView: window.innerWidth < 576 ? "MOBILE" : "DESKTOP",
                orderNumber: tmpOrderNum,
              };
              await api
                .acbaPaymentProvider()
                .post(paymentData)
                .then((res) => {
                  if (res.data && Number(res.data.errorCode) === 0) {
                    window.location.href = res.data.formUrl;
                  }
                })
                .catch((error) => console.log(error));
            }
            setLoading(false);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleRegionChange = (e) => {
    let { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
    setSelectedRegion(geoZones.filter((x) => x.region === value));
  };

  const handleAreaChange = (e) => {
    let { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
    setDeliveryPrice(geoZones.find((x) => x.area === value).amount);
  };

  return (
    <Spin spinning={loading}>
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label={t("Order.FirstName")}>
          {getFieldDecorator("firstName", {
            rules: [
              { required: true, message: `${t("Order.FirstNameMessage")}` },
            ],
          })(
            <Input type="text" name="firstName" onChange={handleInputChange} />
          )}
        </Form.Item>
        <Form.Item label={t("Order.LastName")}>
          <Input type="text" name="lastName" onChange={handleInputChange} />
        </Form.Item>
        <Form.Item label={t("Order.Email")}>
          {getFieldDecorator("mail", {
            rules: [
              {
                type: "email",
                message: `${t("Order.EmailNotValid")}`,
              },
              {
                required: true,
                message: `${t("Order.EmailIsEmpty")}`,
              },
            ],
          })(<Input name="mail" onChange={handleInputChange} />)}
        </Form.Item>
        <Form.Item label={t("Order.PhoneNumber")}>
          {getFieldDecorator("inputs", {
            rules: [
              { required: true, message: `${t("Order.PhoneNumberMessage")}` },
            ],
          })(
            <Input
              name="phone"
              addonBefore={prefixSelector}
              style={{ width: "100%" }}
              onChange={handleInputChange}
            />
          )}
        </Form.Item>
        <Form.Item label={t("Order.Region")}>
          {getFieldDecorator("region", {
            rules: [{ required: true, message: `${t("Order.RegionMessage")}` }],
          })(
            <select
              name="region"
              style={{ width: "100%" }}
              className="customSelect"
              onChange={handleRegionChange}
            >
              <option selected disabled>
                {t("Order.DeliveryRegionMessage")}
              </option>
              {geoZones
                .map((item) => item.region)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((region) => (
                  <option key={region} value={region}>
                    {i18n.language === "en"
                      ? regions.find((x) => x.value === region).name
                      : regions.find((x) => x.value === region).translate}
                  </option>
                ))}
            </select>
          )}
        </Form.Item>
        <Form.Item label={t("Order.Area")}>
          {getFieldDecorator("area", {
            rules: [{ required: true, message: `${t("Order.AreaMessage")}` }],
          })(
            <select
              name="area"
              style={{ width: "100%" }}
              className="customSelect"
              onChange={handleAreaChange}
            >
              <option selected disabled value="disabled">
                {t("Order.DeliveryAreaMessage")}
              </option>
              {selectedRegion
                ? selectedRegion.map((region) => (
                    <option key={region.area} value={region.area}>
                      {i18n.language === "en"
                        ? region.area
                        : region.translate
                        ? region.translate.area
                        : region.area}
                    </option>
                  ))
                : ""}
            </select>
          )}
        </Form.Item>
        <Form.Item label={t("Order.DeliveryAddress")}>
          {getFieldDecorator("address", {
            rules: [
              {
                required: true,
                message: `${t("Order.DeliveryAddressMessage")}`,
              },
            ],
          })(<Input type="text" name="address" onChange={handleInputChange} />)}
        </Form.Item>
        <Form.Item label={t("Order.OrderNotes")}>
          <TextArea
            type="text"
            name="notes"
            onChange={handleInputChange}
            autoSize={{ minRows: 3, maxRows: 50 }}
          />
        </Form.Item>
        <Divider dashed />
        {deliveryPrice !== 0 ? (
          <div className="deliveryPrice">
            {t("Order.DeliveryPrice")}: {deliveryPrice} ֏
          </div>
        ) : (
          ""
        )}
        <div className="totalPrice">
          {t("Cart.Total")}: {props.total + deliveryPrice} ֏{" "}
          {props.byOrderExist ? <span>*</span> : ""}
        </div>
        <Row type="flex" justify="center" align="middle">
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              {t("Order.OrderNow")}
            </Button>
          </Form.Item>
        </Row>
      </Form>
      {props.paymentType === 2 ? (
        <form
          id="idram-form"
          action="https://banking.idram.am/Payment/GetPayment"
          method="POST"
        >
          <input
            type="hidden"
            name="EDP_LANGUAGE"
            value={i18n.language.toUpperCase()}
          />
          <input type="hidden" name="EDP_REC_ACCOUNT" value="110001026" />
          <input type="hidden" name="EDP_DESCRIPTION" value={inputs.notes} />
          <input
            type="hidden"
            name="EDP_AMOUNT"
            value={Number(props.total) + deliveryPrice}
          />
          <input type="hidden" name="EDP_BILL_NO" value={orderNum} />
        </form>
      ) : (
        ""
      )}
    </Spin>
  );
};
const Order = Form.create()(OrderUI);
export default Order;
