import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import { Row, Card, Typography, Skeleton, Col, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { GetPrices, ImageUrl, ManufacturerImageUrl } from "./../Common";

const { Title } = Typography;
const { Meta } = Card;

const API_URL = process.env.REACT_APP_API_URL;

const FetchProducts = (productsIds) => {
  const [images, setImages] = useState();
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [prices, setPrices] = useState([]);
  useEffect(() => {

    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(data);
    };

    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };

    const getImages = async () => {
      if (productsIds.length > 0) {
        await api
          .productsImages()
          .post({ productsIds: productsIds })
          .then((response) => {
            setImages(response.data);
          });
      }
    };

    const getPrices = async () => {
      await api
        .prices()
        .get()
        .then((res) => setPrices(res.data));
    };

    getCategories();
    getManufacturers();
    getImages();
    getPrices();
  }, [productsIds]);
  return {
    images,
    categories,
    manufacturers,
    prices,
  };
};

const Products = ({products, sCategory, productsIds}) => {
  const {
    images,
    categories,
    manufacturers,
    prices,
  } = FetchProducts(productsIds);
  const { t } = useTranslation();
  const [skeletonShow, setSkeleton] = useState(true);

  return (
    <div id="products">
      <Title level={2}>{t("SelectedProducts.1")}</Title>
      <Row type="flex" justify="start" gutter={16} align="top">
        {products
          .filter((x) => images && images.some((y) => y.productId === x.id))
          .map((product) => (
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 8 }}
              style={{ marginBottom: 12 }}
              key={product.id}
            >
              <Link
                to={{
                  pathname: `/product/${product.slug}`,
                  productProps: {
                    id: product.id,
                  },
                }}
              >
                <div className="manufacturerLogoInProduct">
                  {manufacturers &&
                  manufacturers.some((x) => x.id === product.manufacturerId) &&
                  manufacturers.filter(
                    (x) => x.id === product.manufacturerId
                  )[0].imageUrl !== "" &&
                  images !== undefined &&
                  images.some((x) => x.productId === product.id) ? (
                    <img
                      width="100%"
                      src={ManufacturerImageUrl(manufacturers, product.manufacturerId)}
                      alt={
                        manufacturers.find(
                          (x) => x.id === product.manufacturerId
                        ).name
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <img
                  alt="none"
                  onLoad={() => setSkeleton(false)}
                  src={API_URL + "/" + images[0].url}
                  style={{ display: "none" }}
                />
                <Card
                  bordered={false}
                  actions={[
                    <Tag>
                      {categories &&
                      categories.some((x) => x.id === product.categoryId)
                        ? categories.find((x) => x.id === product.categoryId)
                            .name
                        : ""}
                    </Tag>,
                    <div>
                      <div className="oldPrice">
                        {!product.byOrder
                          ? GetPrices(product.id, prices).oldPrice !== 0
                            ? GetPrices(product.id, prices).oldPrice + " ֏"
                            : ""
                          : ""}
                      </div>
                      <div className="cardPrice">
                        {!product.byOrder
                          ? GetPrices(product.id, prices).newPrice  + " ֏"
                          : t("SelectedProducts.ByOrder")}
                      </div>
                    </div>,
                  ]}
                >
                  <Skeleton loading={skeletonShow} avatar active>
                    <Meta
                      title={product.name}
                      avatar={
                        <img
                          className="cardImgSkeleton"
                          key={product.id}
                          alt={product.name}
                          src={ImageUrl(images, product.id)}
                        />
                      }
                    />
                  </Skeleton>
                </Card>
              </Link>
            </Col>
          ))}
      </Row>
    </div>
  );
};
export default Products;
