import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import {
  Row,
  Card,
  Select,
  Form,
  Input,
  InputNumber,
  Divider,
  Button,
  Checkbox
} from "antd";
import { useTranslation } from "react-i18next";

const { Option, OptGroup } = Select;
const InputGroup = Input.Group;

const ResultForm = () => {
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState({ minimum: 0 });
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedManufacturer, setSelectedManufacturer] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [inStock, setInStock] = useState(true);
  const [byOrder, setByOrder] = useState(true);

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(data.sort((a, b) => a.code - b.code));
      setSubCategories(
        data.filter(x => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data.sort((a, b) => a.code - b.code));
    };
    getCategories();
    getManufacturers();
  }, []);

  const handleSubmit = () => {}; //todo search button for prevent server to die
  const { t, i18n } = useTranslation();

  const handleCategoryChange = value => {
    setSelectedCategory(value);
  };
  const handleManufacturerChange = value => {
    setSelectedManufacturer(value);
  };
  const handleMaximumPriceChange = value => {
    setSelectedPrices(selectedPrices => ({
      ...selectedPrices,
      maximum: parseFloat(value)
    }));
  };
  const handleMinimumPriceChange = value => {
    setSelectedPrices(selectedPrices => ({
      ...selectedPrices,
      minimum: parseFloat(value)
    }));
  };

  const handleInStockBoxChange = e => {
    setInStock(e.target.checked);
  };

  const handleByOrderBoxChange = e => {
    setByOrder(e.target.checked);
  };

  return (
    <Row
      className="resultForm"
      type="flex"
      justify="center"
      gutter={16}
      align="top"
    >
      <Card
        bordered={false}
        title={t("Result.Filter")}
        style={{ width: "100%" }}
      >
        <Form
          layout="inline"
          onSubmit={handleSubmit}
          style={{ textAlign: "center" }}
        >
          <Form.Item label={t("Result.Category")}>
            <Select
              name="categoryId"
              defaultValue={0}
              style={{ width: 200 }}
              onChange={handleCategoryChange}
            >
              <Option value={0}>{t("Global.All")}</Option>
              {categories.map(cat =>
                cat.parentId === 0 &&
                subCategories.some(x => x.parentId === cat.id) ? (
                  <OptGroup
                    key={cat.id}
                    label={
                      i18n.language === "en"
                        ? cat.name
                        : cat.translate !== null && cat.translate.name
                        ? cat.translate.name
                        : cat.name
                    }
                  >
                    <Option value={cat.id} key={cat.id}>
                      {t("Global.All")} {" - "} 
                      {i18n.language === "en"
                        ? cat.name
                        : cat.translate !== null && cat.translate.name
                        ? cat.translate.name
                        : cat.name}
                    </Option>
                    {subCategories
                      .filter(x => x.parentId === cat.id)
                      .map(subCat =>
                        subCategories.some(x => x.parentId === subCat.id)
                        ? (
                          <OptGroup
                            id={subCat.id}
                            key={subCat.id}
                            label={
                              i18n.language === "en"
                                ? subCat.name
                                : subCat.translate !== null &&
                                  subCat.translate.name
                                ? subCat.translate.name
                                : subCat.name
                            }
                          >
                            <Option value={subCat.id} key={subCat.id}>
                              {i18n.language === "en"
                                ? subCat.name
                                : subCat.translate !== null &&
                                  subCat.translate.name
                                ? subCat.translate.name
                                : subCat.name}
                            </Option>
                            {subCategories
                              .filter(x => x.parentId === subCat.id)
                              .map(child => (
                                <Option value={child.id} key={child.id}>
                                  {i18n.language === "en"
                                    ? child.name
                                    : child.translate !== null &&
                                      child.translate.name
                                    ? child.translate.name
                                    : child.name}
                                </Option>
                              ))}
                          </OptGroup>
                        ) : (
                          <Option value={subCat.id} key={subCat.id}>
                            {i18n.language === "en"
                              ? subCat.name
                              : subCat.translate !== null &&
                                subCat.translate.name
                              ? subCat.translate.name
                              : subCat.name}
                          </Option>
                        )
                      )}
                  </OptGroup>
                ) : cat.parentId === 0 &&
                  subCategories.filter(x => x.parentId === cat.id).length ===
                    0 ? (
                  <Option value={cat.id} key={cat.id}>
                    {i18n.language === "en"
                      ? cat.name
                      : cat.translate !== null && cat.translate.name
                      ? cat.translate.name
                      : cat.name}
                  </Option>
                ) : (
                  ""
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item label={t("Result.Manufacturer")}>
            <Select
              showSearch
              name="manufacturerId"
              defaultValue={0}
              style={{ width: 200 }}
              onChange={handleManufacturerChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={0}>{t("Global.All")}</Option>
              {manufacturers.map(manufacturer => (
                <Option value={manufacturer.id} key={manufacturer.id}>
                  {i18n.language === "en"
                    ? manufacturer.name
                    : manufacturer.translate !== null &&
                      manufacturer.translate.name
                    ? manufacturer.translate.name
                    : manufacturer.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t("Result.Price")}>
            <InputGroup compact>
              <InputNumber
                min={0}
                style={{ width: 100, textAlign: "center" }}
                placeholder={t("Result.Minimum")}
                onChange={handleMinimumPriceChange}
              />
              <Input
                style={{
                  width: 30,
                  borderLeft: 0,
                  pointerEvents: "none",
                  backgroundColor: "#fff"
                }}
                placeholder="~"
                disabled
              />
              <InputNumber
                min={0}
                style={{ width: 100, textAlign: "center", borderLeft: 0 }}
                placeholder={t("Result.Maximum")}
                onChange={handleMaximumPriceChange}
              />
            </InputGroup>
          </Form.Item>
          <Form.Item>
            <Checkbox defaultChecked={true} onChange={handleInStockBoxChange}>
              {t("ProductPage.InStock")}
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox defaultChecked={true} onChange={handleByOrderBoxChange}>
              {t("ProductPage.ByOrder")}
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Link
              to={{
                pathname: "/result",
                search: `?category=${selectedCategory}&manufacturer=${selectedManufacturer}&minimum=${
                  selectedPrices.minimum
                }&maximum=${
                  selectedPrices.maximum ? selectedPrices.maximum : 0
                }&inStock=${inStock}&byOrder=${byOrder}`
              }}
            >
              <Button type="primary">{t("Result.Find")}</Button>
            </Link>
          </Form.Item>
        </Form>
      </Card>
      <Divider />
    </Row>
  );
};
export default ResultForm;
