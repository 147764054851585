import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Layout, Button, Col, Result } from "antd";
import api from "./../Api";
import NavBar from "../Components/NavBar";
import { useTranslation } from "react-i18next";
import SEO from "../Components/SEO";

const { Content } = Layout;

const OrderDetailsPage = () => {
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [acbaPaymentResult, setAcbaPaymentResult] = useState(null);

  useEffect(() => {
    const getAcbaOrderStatus = async () => {
      const params = new URLSearchParams(location.search);
      if (params.get("orderId")) {
        await api
          .acbaPaymentStatus(params.get("orderId"))
          .get()
          .then((res) => {
            setAcbaPaymentResult(res.data);
          });
      }
    };
    getAcbaOrderStatus();
  }, [location.search]);

  const handleAlreadyOrdered = () => {
    //setInputs({});
    history.push("/");
    window.location.reload();
  };

  return (
    <div id="order-details-page">
      <NavBar from={-2} cartCount={cartCount} setCartCount={setCartCount} />
      <SEO
        pageProps={{
          title:
            "IT Plaza LLC - Online supermarket of computers and accessories",
          thumbnail: "",
          url: window.location,
        }}
      />
      {acbaPaymentResult !== null ? (
        <Content style={{ margin: "24px 24px", maxWidth: "100%" }}>
          <Row justify="space-around" align="middle">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              {acbaPaymentResult.actionCode === 0 ? (
                <Result
                  status="success"
                  title={t("Order.ThankYou")}
                  subTitle={t("Order.ThankYou2")}
                  extra={[
                    <Button
                      onClick={handleAlreadyOrdered}
                      type="primary"
                      key="console"
                    >
                      {t("Order.Back")}
                    </Button>,
                  ]}
                />
              ) : (
                <Result
                  status="error"
                  title={t("Order.Failed")}
                  subTitle={t("Order.Failed2")}
                  extra={[
                    <p className="additionalText">
                      {acbaPaymentResult.actionCode +
                        " " +
                        acbaPaymentResult.actionCodeDescription}
                    </p>,
                    <Button
                      onClick={handleAlreadyOrdered}
                      type="primary"
                      key="console"
                    >
                      {t("Order.Back")}
                    </Button>,
                  ]}
                />
              )}
            </Col>
          </Row>
        </Content>
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderDetailsPage;
